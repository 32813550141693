<template>
  <div class="overall">
   <div class="content">
      <!-- 检索条件 -->
   <el-form ref="form" :model="form"> 
     <el-row :gutter="20">
       <el-col :span="6">
         <el-form label-width="90px">
           <el-form-item label="骑手名字">
               <el-input placeholder="请输入骑手名字" v-model.trim="form.name"></el-input>
           </el-form-item>
         </el-form>
       </el-col>
       <el-col :span="6">
         <el-form label-width="90px">
          <el-form-item label="门店名">
              <el-select v-model="form.siteName" placeholder="请选择门店" style="width: 100%" :clearable="true">
                  <el-option
                    v-for="item in siteIdList"
                    :key="item.value"
                    :label="item.label" 
                    :value="item.label">
                  </el-option>
                </el-select>
            </el-form-item>
         </el-form>
       </el-col>
       <el-col :span="6">
         <el-form label-width="90px">
           <el-form-item label="手机号">
               <el-input placeholder="请输入手机号" v-model.trim="form.phone"></el-input>
           </el-form-item>
         </el-form>
       </el-col>
       <el-col :span="6">
         <el-form label-width="90px">
           <el-form-item label="地址">
               <el-input placeholder="请输入地址" v-model.trim="form.address"></el-input>
           </el-form-item>
         </el-form>
       </el-col>
     </el-row>
     <el-row :gutter="20">
       <el-col :span="6" style="margin-left: 90px;">
           <el-button type="primary" @click="seach()">查询</el-button>
           <el-button type="primary" @click="reset()">重置</el-button>
       </el-col>
     </el-row>
   </el-form>

   <!-- table -->
   <div class="tableBox">
           <el-table
               :data="tableData"
               v-loading="tableLoading"
               max-height="650"
               border
               ref="multipleTable"
               style="width: 100%">
               <!-- <el-table-column
               type="selection"
               width="55">
               </el-table-column> -->
               <el-table-column
                   prop="createAt" 
                   label="创建时间"
                   width="200">
               </el-table-column>
               <el-table-column
                   prop="name" 
                   label="骑手">
               </el-table-column>
               <el-table-column
                   prop="phone" 
                   label="手机号">
               </el-table-column>
               <el-table-column
                   prop="siteName" 
                   label="门店">
               </el-table-column>
               <el-table-column
                   prop="address" 
                   label="地址"
                   width="350px">
                   <template slot-scope="scope">
                     {{scope.row.address + scope.row.addressList}}
                   </template>
               </el-table-column>
               <el-table-column
                   prop="status" 
                   label="审核状态">
                   <template slot-scope="scope">
                     {{scope.row.status === 1 ? '待签署' : '已签署'}}
                   </template>
               </el-table-column>
              
               <el-table-column
                   prop="startTime" 
                   label="协议生效时间">
               </el-table-column>
               <el-table-column
                   prop="endTime" 
                   label="协议到期时间">
               </el-table-column>
               <el-table-column
               label="操作" >
                <template slot-scope="scope" >
                  <el-button v-if="scope.row.status === 2"
                  @click.native.prevent="downloadExcel(scope.$index, scope.row)"
                  type="primary">
                  下载协议
                  </el-button>
                  <el-button
                  v-else
                  disabled="true"
                  type="info">
                  下载协议
                  </el-button>
                </template>
              </el-table-column>
           </el-table>
       </div>
<!-- 分页 -->
       <div class="paginationBox">
         <el-pagination
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
           :current-page="currentPage"
           :page-sizes="[10, 20, 50, 100]"
           :page-size="pageSize"
           layout="total, prev, pager, next, jumper"
           background
           :total="total">
         </el-pagination>
       </div>
     
   </div>
  </div>
</template>

<script>
import {getList} from '../../../api/subcontractProtocol';
import {siteInfoList,} from '../../../api/restaurant';
export default {
 data(){
   return{
     form:{
       name:"",
       siteName:"",
       phone:"",
       address:"",
     },
       siteIdList:[],
       name:"",
       siteName:"",
       phone:"",
       address:"",
       addressList:"",
       url:"",
       startTime:"",
       endTime:"",
       createAt:"",
       ids:[],
       total:0,
       tableData: [],
       tableLoading:false,
       currentPage:1,
       pageSize:10,
   }
 },
 mounted(){
   this.SubcontractProtocolList();
   this.siteInfoList();
 },
 methods:{
   async SubcontractProtocolList(){
     try {
       const params = {
       page : this.currentPage,
       limit: this.pageSize
     }
     let values = {
       ...params,
       ...this.form
     }
     this.tableLoading=true;
     let data =  await getList(values);
     this.total = data.total;
     this.tableData = data.deliveryContractList;
     } catch (error) {
       console.log("error",error);
     }
     this.tableLoading = false;
   },
   //搜索
   seach() {
     this.SubcontractProtocolList();
   },
   // 重置
   reset() {
     this.form.name = "";
     this.form.siteName="",
     this.form.phone="",
     this.form.address="",
     this.currentPage = 1;
     this.SubcontractProtocolList();
   },
   //复选框
   handleSelectionChange(selectionList) {
            this.ids = [];
            selectionList.forEach(ele => {
            this.ids.push(ele.id);
        });
        return this.ids.join(",");
      },

      downloadExcel(index,row) {
        let a = document.createElement("a");
        a.href = row.url;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        a.remove();
      },

      //更改一页的条数
      handleSizeChange(size) {
            this.pageSize = size;
            //重新走页面初始化方法
            this.detailLabelList();
        },
        //更改页数
        handleCurrentChange(current) {
            this.currentPage = current;
            //重新走页面初始化方法
            this.detailLabelList();
        },
        // 门店下拉
    async siteInfoList() {
      try {
        let data = await siteInfoList();
        const hubArr = [];
        data.forEach(item => {
          hubArr.push({
            value: item.id,
            label: item.name
          })
        })
        this.siteIdList = hubArr;
      } catch (error) {
        console.log("error:", error)
      }
      this.tableLoading = false;
    },

 }

}
</script>

<style lang="scss" scoped>
.overall {
   .content{
       .tableBox {
           padding-top: 20px;
       }
       .paginationBox {
           padding-top: 20px;
           text-align: center;
       }
   }
}
</style>